import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  BlankStateComponent,
  LoaderComponent,
  ModalCloseDirective,
  ModalContentDirective,
  ModalTitleDirective,
  PanelInfoComponent,
  SearchBarComponent,
  TrackByPipe,
} from '@dougs/ds';
import { ModalOperationStateService, OPERATION_STATE_TOKEN } from '@dougs/operations/shared';
import { OperationEcommerceComponentService } from '../../services/operation-ecommerce.component.service';
import { SelectDestinationZoneModalComponentService } from '../../services/select-destination-zone-modal.component.service';
import { SelectDeliveryZoneModalComponent } from './select-delivery-zone/select-delivery-zone.component';
import { SelectDestinationCountriesModalComponent } from './select-destination-countries/select-destination-countries.component';
import { SelectOutsideEuCountriesModalComponent } from './select-outside-eu-countries/select-outside-eu-countries.component';
import { SelectPackageZoneModalComponent } from './select-package-zone/select-package-zone.component';

@Component({
  selector: 'dougs-select-destination-zone-modal',
  templateUrl: './select-destination-zone-modal.component.html',
  styleUrls: ['./select-destination-zone-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    CommonModule,
    TrackByPipe,
    SearchBarComponent,
    ReactiveFormsModule,
    BlankStateComponent,
    PanelInfoComponent,
    LoaderComponent,
    SelectDeliveryZoneModalComponent,
    SelectPackageZoneModalComponent,
    SelectDestinationCountriesModalComponent,
    SelectOutsideEuCountriesModalComponent,
  ],
  providers: [
    SelectDestinationZoneModalComponentService,
    OperationEcommerceComponentService,
    {
      provide: OPERATION_STATE_TOKEN,
      useExisting: ModalOperationStateService,
    },
  ],
  animations: [
    trigger('slideInFromRight', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('200ms cubic-bezier(0.25, 0.8, 0.25, 1)', style({ transform: 'translateX(0)' })),
      ]),
    ]),
    trigger('slideInFromLeft', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('200ms cubic-bezier(0.25, 0.8, 0.25, 1)', style({ transform: 'translateX(0)' })),
      ]),
    ]),
  ],
})
export class SelectDestinationZoneModalComponent implements OnInit {
  constructor(public readonly selectDestinationZoneModalComponentService: SelectDestinationZoneModalComponentService) {}

  async ngOnInit(): Promise<void> {
    this.selectDestinationZoneModalComponentService.refreshDestinationZones();
  }
}
