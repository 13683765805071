import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  BlankStateComponent,
  LoaderComponent,
  ModalCloseDirective,
  ModalContentDirective,
  ModalTitleDirective,
  PanelInfoComponent,
  SearchBarComponent,
  TrackByPipe,
} from '@dougs/ds';
import { SelectDestinationZoneModalComponentService } from '../../../services/select-destination-zone-modal.component.service';

@Component({
  selector: 'dougs-select-outside-eu-countries',
  templateUrl: './select-outside-eu-countries.component.html',
  styleUrls: ['../select-destination-zone-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    CommonModule,
    TrackByPipe,
    SearchBarComponent,
    ReactiveFormsModule,
    BlankStateComponent,
    PanelInfoComponent,
    LoaderComponent,
  ],
  animations: [
    trigger('slideInFromRight', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('200ms cubic-bezier(0.25, 0.8, 0.25, 1)', style({ transform: 'translateX(0)' })),
      ]),
    ]),
  ],
})
export class SelectOutsideEuCountriesModalComponent {
  constructor(public readonly selectDestinationZoneModalComponentService: SelectDestinationZoneModalComponentService) {}

  @ViewChild('outsideEuSearchBar', { static: false }) set searchBar(searchBar: SearchBarComponent) {
    if (searchBar) {
      searchBar?.focusSearch();
    }
  }
}
