import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BlankStateComponent, LoaderComponent, PanelInfoComponent, SearchBarComponent, TrackByPipe } from '@dougs/ds';
import { SelectDestinationZoneModalComponentService } from '../../../services/select-destination-zone-modal.component.service';

@Component({
  selector: 'dougs-select-delivery-zone',
  templateUrl: './select-delivery-zone.component.html',
  styleUrls: ['../select-destination-zone-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TrackByPipe,
    SearchBarComponent,
    ReactiveFormsModule,
    BlankStateComponent,
    PanelInfoComponent,
    LoaderComponent,
  ],
})
export class SelectDeliveryZoneModalComponent {
  constructor(public readonly selectDestinationZoneModalComponentService: SelectDestinationZoneModalComponentService) {}

  @Input() zones: any;
  @Input() animationIsReady = false;
}
