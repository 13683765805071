<ng-container *ngIf="selectDestinationZoneModalComponentService.searchCountry$ | async"></ng-container>
<div class="ecommerce-modal-content__container" [@slideInFromRight]>
  <div class="menu__container">
    <dougs-search-bar
      #searchBar
      class="mb-24"
      [formControl]="selectDestinationZoneModalComponentService.searchFormControl"
      [searchInProgress]="!!selectDestinationZoneModalComponentService.searchFormControl.value"
      (clearSearch)="selectDestinationZoneModalComponentService.onClearSearch()"
      size="small"
    ></dougs-search-bar>
    <div
      class="back-header my-16 px-32 pointer"
      (click)="selectDestinationZoneModalComponentService.selectPackageType()"
    >
      <i class="fal fa-chevron-left"></i>
      <h6>Retour</h6>
    </div>
    <div
      *ngIf="
        !selectDestinationZoneModalComponentService.isSearching &&
        selectDestinationZoneModalComponentService.menuItems?.length &&
        selectDestinationZoneModalComponentService.selectedPackage?.packageId === 'sub150'
      "
      class="px-32"
    >
      <ul>
        <li
          *ngFor="
            let menuItem of selectDestinationZoneModalComponentService.menuItems$ | async;
            trackBy: 'id' | trackBy
          "
        >
          <a
            (click)="selectDestinationZoneModalComponentService.onMenuChange($event, menuItem)"
            href="#"
            [ngClass]="{
              active: (selectDestinationZoneModalComponentService.activeMenuType$ | async) === menuItem.id
            }"
            class="p-4"
          >
            {{ menuItem.label }}
          </a>
        </li>
      </ul>
      <div class="menu__border-bottom"></div>
    </div>
  </div>
  <div class="px-32">
    <div
      (click)="selectDestinationZoneModalComponentService.selectDestinationCountry(destinationCountry)"
      class="line pointer py-8"
      *ngFor="
        let destinationCountry of selectDestinationZoneModalComponentService.filteredCountries$ | async;
        trackBy: 'packageId' | trackBy
      "
    >
      <div class="line__flag">
        <img *ngIf="destinationCountry.flag" class="mr-8" src="images/flags/{{ destinationCountry.flag }}.svg" />
      </div>
      <div
        [ngClass]="{
          line__label: selectDestinationZoneModalComponentService.selectedCountry.type !== 'exonerateSale'
        }"
      >
        <p class="small color-primary-700">
          {{ destinationCountry.label }}
          <span *ngIf="selectDestinationZoneModalComponentService.selectedCountry.type !== 'exonerateSale'">
            -&nbsp;
          </span>
        </p>
        <p class="tiny" [innerHtml]="destinationCountry.subLabel"></p>
      </div>
    </div>
    <dougs-blank-state
      class="px-32 mt-32"
      *ngIf="!(selectDestinationZoneModalComponentService.filteredCountries$ | async)?.length"
    >
      <h5>Aucun résultat</h5>
    </dougs-blank-state>
  </div>
</div>
