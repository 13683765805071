import { Inject, Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Intercom } from '@supy-io/ngx-intercom';
import {
  BehaviorSubject,
  combineLatest,
  concatMap,
  debounceTime,
  distinctUntilChanged,
  first,
  map,
  Observable,
  ReplaySubject,
  startWith,
  tap,
} from 'rxjs';
import { CompanyStateService } from '@dougs/company/shared';
import { MODAL_DATA, ModalRef } from '@dougs/ds';
import {
  DestinationZone,
  EcommerceDestinationCountry,
  EcommerceModalPanelInfo,
  PackageType,
  Zone,
} from '@dougs/ecommerce/dto';
import { EcommerceSaleStateService, SalesChannelStateService } from '@dougs/ecommerce/shared';
import { Operation } from '@dougs/operations/dto';
import { OperationEcommerceMetricsService } from './operation-ecommerce-metrics.service';
import { OperationEcommerceComponentService } from './operation-ecommerce.component.service';

@Injectable()
export class SelectDestinationZoneModalComponentService {
  private readonly isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  isLoading$: Observable<boolean> = this.isLoading.asObservable();

  private readonly filteredCountriesSubject: ReplaySubject<EcommerceDestinationCountry[]> = new ReplaySubject<
    EcommerceDestinationCountry[]
  >(1);
  filteredCountries$: Observable<EcommerceDestinationCountry[]> = this.filteredCountriesSubject.asObservable();

  private readonly zonesSubject: ReplaySubject<Zone[]> = new ReplaySubject<Zone[]>(1);
  zones$: Observable<Zone[]> = this.zonesSubject.asObservable();

  private readonly selectedZoneSubject: BehaviorSubject<Zone | undefined> = new BehaviorSubject<Zone | undefined>(
    undefined,
  );
  selectedZone$: Observable<Zone | undefined> = this.selectedZoneSubject.asObservable();

  private readonly activeMenuTypeSubject: ReplaySubject<string> = new ReplaySubject<string>(1);
  activeMenuType$: Observable<string> = this.activeMenuTypeSubject.asObservable();

  private readonly menuItemsSubject: ReplaySubject<{ id: string; label: string }[]> = new ReplaySubject<
    { id: string; label: string }[]
  >(1);
  menuItems$: Observable<{ id: string; label: string }[]> = this.menuItemsSubject.asObservable();

  private readonly refreshDestinationZonesSubject: ReplaySubject<void> = new ReplaySubject<void>(1);
  refreshDestinationZones$: Observable<Zone[]> = this.refreshDestinationZonesSubject.asObservable().pipe(
    tap(() => this.isLoading.next(true)),
    concatMap(() => this.ecommerceSaleStateService.getDestinationZones(this.data.operation)),
    tap((zones: Zone[]) => this.zonesSubject.next(zones)),
    tap(() => this.isLoading.next(false)),
  );

  private readonly titleSubject: BehaviorSubject<string> = new BehaviorSubject<string>(
    'Sélectionner la zone de livraison',
  );
  title$: Observable<string> = this.titleSubject.asObservable();

  constructor(
    @Inject(MODAL_DATA)
    public data: { sectionId: string; subSectionId: string; operation: Operation },
    private readonly ecommerceSaleStateService: EcommerceSaleStateService,
    private readonly operationEcommerceComponentService: OperationEcommerceComponentService,
    private readonly operationEcommerceMetricsService: OperationEcommerceMetricsService,
    private readonly salesChannelStateService: SalesChannelStateService,
    private readonly companyStateService: CompanyStateService,
    private readonly intercom: Intercom,
    private readonly modalRef: ModalRef,
  ) {}

  hasIossAndIsMarketplace$: Observable<boolean> = combineLatest([
    this.salesChannelStateService.salesChannels$,
    this.companyStateService.activeCompany$,
  ]).pipe(
    first(),
    map(
      ([salesChannels, activeCompany]) =>
        salesChannels.some(
          (salesChannel) =>
            salesChannel.category === 'marketPlace' &&
            this.data.operation?.metadata?.salesChannelId === salesChannel.id,
        ) && activeCompany.accountingConfiguration.hasIoss,
    ),
  );

  isSearching = false;
  animationIsReady = false;
  public selectedZone?: Zone;
  public selectedCountry?: DestinationZone;
  animationIsReadyForCountry = false;
  public selectedPackage?: PackageType;
  public selectedEuPanelInfo?: EcommerceModalPanelInfo;
  public searchFormControl: FormControl<string> = new FormControl<string>('', { nonNullable: true });
  public outsideEuSearchFormControl: FormControl<string> = new FormControl<string>('', { nonNullable: true });
  public allCountries!: EcommerceDestinationCountry[];
  public filteredCountries!: EcommerceDestinationCountry[];
  public menuItems!: { id: string; label: string }[];
  public activeMenuType?: string;

  readonly searchCountry$: Observable<string> = this.searchFormControl.valueChanges.pipe(
    debounceTime(300),
    distinctUntilChanged(),
    tap((search: string) => (this.isSearching = !!search)),
    startWith(''),
    tap(() => this.filterCountries(this.searchFormControl.value)),
  );

  readonly outsideEuSearchFormControl$: Observable<string> = this.outsideEuSearchFormControl.valueChanges.pipe(
    debounceTime(300),
    distinctUntilChanged(),
    tap((search: string) => (this.isSearching = !!search)),
    startWith(''),
    tap(() => this.filterCountries(this.outsideEuSearchFormControl.value)),
  );

  refreshDestinationZones(): void {
    this.refreshDestinationZonesSubject.next();
  }

  selectDestinationZone(zone?: Zone): void {
    this.selectedZoneSubject.next(zone);
    this.selectedZone = zone;
    this.animationIsReady = true;
    if (zone?.zone === 'outsideEu') {
      if (zone?.countries) {
        this.allCountries = zone?.countries;
        this.filterCountries(this.outsideEuSearchFormControl.value);
      }
      this.titleSubject.next('Sélectionner le pays de livraison');
    } else if (zone?.zone === 'eu') {
      this.titleSubject.next('Vos ventes ont été réalisées à partir de :');
    } else {
      this.titleSubject.next('Sélectionner la zone de livraison');
    }
  }

  selectOutsideEuCountries(destinationCountry: EcommerceDestinationCountry): void {
    this.modalRef.close({
      sectionId: this.data.sectionId,
      subSectionId: this.data.subSectionId,
      selectedZone: this.selectedZone?.zone,
      saleId: destinationCountry.saleId,
      packageId: this.selectedPackage?.packageId,
    });
  }

  async selectPackageType(packageType?: PackageType): Promise<void> {
    if (
      !this.companyStateService.activeCompany.accountingConfiguration.hasIoss &&
      packageType?.packageId === 'sub150'
    ) {
      return;
    }
    this.selectedPackage = packageType;
    this.animationIsReadyForCountry = true;
    this.selectedEuPanelInfo = this.selectedPackage?.destinationCountries.find((zone) => zone.type === 'eu')?.panelInfo;
    if (packageType) {
      this.titleSubject.next('Sélectionnez le taux de TVA du pays de livraison');
      this.allCountries = packageType.destinationCountries.reduce(
        (allCountries, zone) => allCountries.concat(...zone.countries),
        [] as EcommerceDestinationCountry[],
      );
      if (
        packageType.packageId === 'sub150' &&
        (await this.operationEcommerceComponentService.isMarketplaceDispatch(this.data.operation))
      ) {
        this.operationEcommerceMetricsService.trackEcommerceOperationDispatchSelectPackageButtonClicked(
          this.data.sectionId,
          this.data.subSectionId,
          this.data.operation,
          this.operationEcommerceComponentService.salesChannel,
          packageType.packageId,
        );
        this.modalRef.close({
          sectionId: this.data.sectionId,
          subSectionId: this.data.subSectionId,
          selectedZone: this.selectedZone?.zone,
          saleId: this.selectedZone?.zone,
          packageId: packageType.packageId,
        });
      }
      this.assignActiveMenu(packageType);
    } else {
      this.titleSubject.next('Vos ventes ont été réalisées à partir de :');
    }
  }

  async selectDestinationCountry(destinationCountry: EcommerceDestinationCountry): Promise<void> {
    this.operationEcommerceMetricsService.trackEcommerceOperationDispatchSelectPackageButtonClicked(
      this.data.sectionId,
      this.data.subSectionId,
      this.data.operation,
      this.operationEcommerceComponentService.salesChannel,
      this.selectedPackage?.packageId,
      destinationCountry.saleId,
    );
    this.modalRef.close({
      sectionId: this.data.sectionId,
      subSectionId: this.data.subSectionId,
      selectedZone: this.selectedZone?.zone,
      saleId:
        (await this.operationEcommerceComponentService.hasIossAndIsMarketPlace(this.data.operation)) &&
        this.selectedPackage?.packageId === 'sub150'
          ? 'eu'
          : destinationCountry.saleId,
      packageId: this.selectedPackage?.packageId,
    });
  }

  private assignActiveMenu(packageType: PackageType): void {
    this.menuItems = packageType.destinationCountries.map((country) => ({
      id: country.type,
      label: country.title,
    }));
    this.activeMenuType = this.menuItems?.[0]?.id;
    this.menuItemsSubject.next(this.menuItems);
    this.activeMenuTypeSubject.next(this.activeMenuType);
  }

  onMenuChange(e: MouseEvent, menuItem: { id: string; label: string }): void {
    e.preventDefault();
    if (this.activeMenuType === menuItem.id) {
      return;
    }
    this.activeMenuType = menuItem.id;
    this.activeMenuTypeSubject.next(this.activeMenuType);
    this.filterCountries(this.searchFormControl.value);
  }

  public filterCountries(searchFormControlValue: string): void {
    this.selectedCountry = this.selectedPackage?.destinationCountries.find((zone) => zone.type === this.activeMenuType);
    const searchValue: string = searchFormControlValue.toLowerCase();
    if (searchValue) {
      this.filteredCountries = this.allCountries.filter((country) => country.label.toLowerCase().includes(searchValue));
    } else if (this.selectedCountry && this.selectedPackage?.packageId !== 'over150') {
      this.filteredCountries = this.selectedCountry.countries.filter(
        (country) =>
          country.label.toLowerCase().includes(searchValue) || country.subLabel.toLowerCase().includes(searchValue),
      );
    } else {
      this.filteredCountries = [...this.allCountries];
    }
    this.filteredCountriesSubject.next(this.filteredCountries);
  }

  onClearSearch(): void {
    this.searchFormControl.reset('');
  }

  openIntercom(e: Event): void {
    e.preventDefault();
    this.intercom.show();
  }
}
