<div dougsModalTitle>
  <h6>{{ selectDestinationZoneModalComponentService.title$ | async }}</h6>
  <i dougsModalClose class="fal fa-times"></i>
</div>
<div class="force-max-height no-padding" dougsModalContent>
  <ng-container *ngIf="selectDestinationZoneModalComponentService.refreshDestinationZones$ | async"></ng-container>
  <ng-container *ngIf="!(selectDestinationZoneModalComponentService.isLoading$ | async); else loader">
    <dougs-select-delivery-zone
      *ngIf="
        (selectDestinationZoneModalComponentService.zones$ | async)?.length &&
        !(selectDestinationZoneModalComponentService.selectedZone$ | async)
      "
      [@slideInFromLeft]
      [@.disabled]="!selectDestinationZoneModalComponentService.animationIsReady"
      [zones]="selectDestinationZoneModalComponentService.zones$ | async"
    ></dougs-select-delivery-zone>
    <div
      *ngIf="
        (selectDestinationZoneModalComponentService.selectedZone$ | async) &&
        !selectDestinationZoneModalComponentService.selectedPackage
      "
      [@slideInFromRight]
    >
      <dougs-select-outside-eu-countries
        *ngIf="(selectDestinationZoneModalComponentService.selectedZone$ | async).zone === 'outsideEu'"
      ></dougs-select-outside-eu-countries>
      <dougs-select-package-zone
        *ngIf="(selectDestinationZoneModalComponentService.selectedZone$ | async).zone === 'eu'"
      ></dougs-select-package-zone>
    </div>
    <dougs-select-destination-countries
      *ngIf="selectDestinationZoneModalComponentService.selectedPackage"
    ></dougs-select-destination-countries>
  </ng-container>
  <ng-template #loader>
    <dougs-loader></dougs-loader>
  </ng-template>
</div>
