<div
  class="back-header mt-16 px-32 mb-16 pointer"
  (click)="selectDestinationZoneModalComponentService.selectDestinationZone()"
>
  <i class="fal fa-chevron-left"></i>
  <h6>Retour</h6>
</div>
<div
  (click)="selectDestinationZoneModalComponentService.selectPackageType(packageType)"
  class="default-line pointer mx-32 py-8"
  *ngFor="
    let packageType of (selectDestinationZoneModalComponentService.selectedZone$ | async).packageTypes;
    trackBy: 'packageId' | trackBy
  "
>
  <div
    class="default-line__label"
    [ngClass]="{
      disabled:
        !(companyStateService.activeCompany$ | async).accountingConfiguration.hasIoss &&
        packageType.packageId === 'sub150'
    }"
  >
    <img *ngIf="packageType.flag" class="mr-8" src="images/flags/{{ packageType.flag }}.svg" />
    <p [innerHTML]="packageType.label" class="small color-primary-700"></p>
  </div>
  <i
    *ngIf="
      packageType.packageId === 'over150' ||
      (packageType.packageId === 'sub150' &&
        !(selectDestinationZoneModalComponentService.hasIossAndIsMarketplace$ | async))
    "
    class="fal fa-chevron-right color-primary-700 mr-4"
    [ngClass]="{
      disabled:
        !(companyStateService.activeCompany$ | async).accountingConfiguration.hasIoss &&
        packageType.packageId === 'sub150'
    }"
  ></i>
</div>
<div class="mt-32 px-32">
  <dougs-panel-info
    type="default-light"
    *ngIf="!(companyStateService.activeCompany$ | async).accountingConfiguration.hasIoss"
  >
    <p class="small color-primary-700">
      Pour faire des ventes inférieures à 150€ HT en Union Européenne en provenance d'un pays tiers, vous devez être
      inscrit à l'iOSS.
      <a href="#" (click)="selectDestinationZoneModalComponentService.openIntercom($event)">Contactez-nous.</a>
    </p>
  </dougs-panel-info>
</div>
