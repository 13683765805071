<ng-container *ngIf="selectDestinationZoneModalComponentService.outsideEuSearchFormControl$ | async"></ng-container>
<div class="ecommerce-modal-content__container" [@slideInFromRight]>
  <div class="menu__container">
    <dougs-search-bar
      #outsideEuSearchBar
      class="mb-24"
      [formControl]="selectDestinationZoneModalComponentService.outsideEuSearchFormControl"
      [searchInProgress]="!!selectDestinationZoneModalComponentService.outsideEuSearchFormControl.value"
      (clearSearch)="selectDestinationZoneModalComponentService.onClearSearch()"
      size="small"
    ></dougs-search-bar>
    <div
      class="back-header mb-16 px-32 pointer"
      (click)="selectDestinationZoneModalComponentService.selectDestinationZone()"
    >
      <i class="fal fa-chevron-left"></i>
      <h6>Retour</h6>
    </div>
  </div>
  <div
    (click)="selectDestinationZoneModalComponentService.selectOutsideEuCountries(destinationCountry)"
    class="default-line pointer mx-32 py-8"
    *ngFor="
      let destinationCountry of selectDestinationZoneModalComponentService.filteredCountries$ | async;
      trackBy: 'saleId' | trackBy
    "
  >
    <div class="default-line__label">
      <img *ngIf="destinationCountry.flag" class="mr-8" src="images/flags/{{ destinationCountry.flag }}.svg" />
      <p [innerHTML]="destinationCountry.label" class="small color-primary-700"></p>
    </div>
  </div>
  <dougs-blank-state
    class="px-32 mt-32"
    *ngIf="!(selectDestinationZoneModalComponentService.filteredCountries$ | async)?.length"
  >
    <h5>Aucun résultat</h5>
  </dougs-blank-state>
</div>
