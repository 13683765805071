import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CompanyStateService } from '@dougs/company/shared';
import {
  BlankStateComponent,
  LoaderComponent,
  ModalCloseDirective,
  ModalContentDirective,
  ModalTitleDirective,
  PanelInfoComponent,
  SearchBarComponent,
  TrackByPipe,
} from '@dougs/ds';
import { SelectDestinationZoneModalComponentService } from '../../../services/select-destination-zone-modal.component.service';

@Component({
  selector: 'dougs-select-package-zone',
  templateUrl: './select-package-zone.component.html',
  styleUrls: ['../select-destination-zone-modal.component.scss', './select-package-zone.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    CommonModule,
    TrackByPipe,
    SearchBarComponent,
    ReactiveFormsModule,
    BlankStateComponent,
    PanelInfoComponent,
    LoaderComponent,
  ],
})
export class SelectPackageZoneModalComponent {
  hasIossAndIsMarketPlace?: boolean;
  constructor(
    public readonly selectDestinationZoneModalComponentService: SelectDestinationZoneModalComponentService,
    public readonly companyStateService: CompanyStateService,
  ) {}
}
