<div class="mt-16 px-32" *ngIf="zones?.length">
  <div
    (click)="selectDestinationZoneModalComponentService.selectDestinationZone(destinationZone)"
    class="default-line pointer p-8"
    *ngFor="let destinationZone of zones; trackBy: 'flag' | trackBy"
  >
    <div class="default-line__label">
      <img *ngIf="destinationZone.flag" class="mr-8" src="images/flags/{{ destinationZone.flag }}.svg" />
      <p class="small color-primary-700">
        {{ destinationZone.label }}
      </p>
    </div>
    <i class="fal fa-chevron-right color-primary-700 mr-4"></i>
  </div>
</div>
